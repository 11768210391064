<template>
    <modal name="warning_modal" height="auto">
        <div class="tw-flex tw-flex-col tw-box-border tw-px-6 tw-mt-6 tw-py-6 tw-rounded-xl tw-items-center tw-justify-center">
            <template>
                <div class="icon tw-mb-4 tw-text-red-400 tw-w-16 tw-h-16" :class="isWarning ? 'tw-text-red-400' : 'tw-text-green-400'">
                    <template v-if="!isWarning">
                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                    </template>
                    <template v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                        </svg>
                    </template>
                </div>
                <p class="tw-text-xl tw-font-medium tw-text-gray-700 tw-mb-4 tw-mt-0 tw-tracking-wider">
                    <template v-if="isWarning"> Are you sure? </template>
                    <template v-else> Start research process! </template>
                </p>
                <div class="tw-tracking-wide tw-leading-relaxed tw-text-center tw-text-gray-500" v-if="isWarning">
                    Please confirm that you want to delete all the entities and cancel case creation? Person and company organization information you have entered will be gone.
                </div>
                <div class="tw-tracking-wide tw-leading-relaxed tw-text-center tw-text-gray-500" v-else>
                    A new case will be created, you will not be able to edit any more information, start the research process using the information you’ve entered
                </div>

                <div class="tw-flex tw-justify-evenly tw-w-full tw-mt-6 tw-space-x-6">
                    <button @click="$modal.hide(`warning_modal`)" :class="isWarning ? 'tw-text-red-800 hover:tw-text-white tw-bg-red-100 hover:tw-bg-red-700' : 'tw-text-green-800 hover:tw-text-white tw-bg-green-100 hover:tw-bg-green-700'" class="tw-w-1/2 tw-px-6 tw-py-2.5 tw-border tw-cursor-pointer tw-border-transparent tw-text-base tw-font-medium tw-rounded-md tw-text-red-800 hover:tw-text-white tw-bg-red-100 hover:tw-bg-red-700">
                        Close
                    </button>
                    <button @click="$emit(isWarning ? `cancelCase` : `createCase`)" :class="isWarning ? 'tw-bg-red-500 hover:tw-bg-red-700' : 'tw-bg-green-500 hover:tw-bg-green-700'" class="tw-w-1/2 tw-px-6 tw-py-2.5 tw-border tw-cursor-pointer tw-border-transparent tw-text-base tw-font-medium tw-rounded-md tw-text-white tw-bg-red-500 hover:tw-bg-red-700" :disabled="caseCreateLoader">
                        <template v-if="isWarning"> Cancel Case </template>
                        <template v-else>
                            {{ caseCreateLoader ? "Creating..." : "Create Case" }}
                        </template>
                    </button>
                </div>
            </template>
        </div>
    </modal>
</template>

<script>
export default {
    data() {
        return {};
    },

    methods: {},

    props: {
        isWarning: {
            type: Boolean,
        },
        caseCreateLoader: {
            type: Boolean,
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .vm--modal {
        border-radius: 12px;
    }
}
</style>
