// import Button from "@/components/button";
import entityPanel from "./components/entity-sidepanel.vue";
import entityTabs from "./components/entity-tabs.vue";
import modalWarn from "./components/modal-warn.vue";
import Tabs from "@/components/tab";
import {EventBus} from "@/main.js";
// import FileBrowser from "@/components/filebrowser"
import modalConfirm from "@/components/modal-confirm";
import axios from "@/axios";
import {mapGetters} from "vuex";
import {cloneDeep} from "lodash";
import neoLoader from "@/components/loader";
import nLoader from "@/components/n-loader";
import DynamicForm from "@/components/DynamicForm";
import moment from "moment";
import OsintAxios from "@/axios/osint.js";
// import { FILE_MANAGER_FOLDERS } from "@/utils/constants";
const FileExplorer = () => import("@/containers/file-explorer");

const TOP_TABS = [
    {
        key: "input",
        name: "Input",
        enabled: true,
        readOnly: true,
    },
    {
        key: "output",
        name: "Output",
        enabled: true,
        readOnly: true,
    },
];

export default {
    name: "neo-create-case-entities",
    components: {
        entityTabs,
        entityPanel,
        modalWarn,
        neoLoader,
        nLoader,
        Tabs,
        // FileBrowser,
        modalConfirm,
        DynamicForm,
        FileExplorer,
    },
    props: {
        currentTab: {
            type: String,
            default: "input",
        },
    },

    data() {
        return {
            selectedEntity: null,
            selected_menu: "profile_data",
            entities: [],
            modalProps: {
                heading: "Are you sure?",
                bodyText: "",
                icon: "tick",
            },
            currentEntityInformation: null,
            isModalWarn: true,
            isEntityLoading: false,
            isCurrentEntityLoading: false,
            tabs: TOP_TABS,
            // folders: FILE_MANAGER_FOLDERS,
            case_id: "",
            isCaseInProgress: false,
            isFormReadOnly: false,
            case_status: "In Progress",
            caseCreateLoader: false,
            loaderController: {
                isSaving: true,
                showLoader: false,
                loaderText: "Saving...",
            },
            validationForm: [],
            validationValues: [],
            validationKey: "",
            isFormValid: true,
            allCountryCodes: [],
            countryCodes: [],
            dataRetention: null,
            legalReason: "",
        };
    },
    computed: {
        ...mapGetters(["getCaseMaxEntity", "getProduct", "getCaseEntityInfo", "getCaseId", "getUserAuthDetails", "getReadOnlyMode"]),

        checkPersonInEntity() {
            return this.getCaseEntityInfo?.["person"] ? true : false;
        },

        checkCompanyInEntity() {
            return this.getCaseEntityInfo?.["company"] ? true : false;
        },

        entityInformation() {
            let info, menu, entities;
            info = [];
            menu = [];
            entities = ["person", "company"];
            entities.forEach((ent) => {
                if (this.getCaseEntityInfo?.[ent])
                    Object.keys(this.getCaseEntityInfo?.[ent])
                        ?.reverse()
                        ?.map((el, idx) => {
                            menu.push({
                                name: this.getCaseEntityInfo[ent][el]["heading"],
                                tab: el,
                                id: idx + 1,
                                icon: "",
                                entity_type: this.getCaseEntityInfo[ent][el]["entity_type"],
                                type: ent,
                                order: this.getCaseEntityInfo[ent][el]["order"],
                            });
                            // }
                        });
            });
            info.push({
                label: "Entity Information",
                id: 0,
                menus: menu.sort((a, b) => a.order - b.order),
            });
            return info;
        },

        isNeotasUser() {
            return this.getUserAuthDetails.email.includes("@neotas.com");
        },

        currentEntity() {
            return this.entities.length ? this.entities[this.selectedEntity] : null;
        },
        entityName() {
            return [{name: this.currentEntity?.entity ?? ""}];
        },

        currentEntityFormData() {
            return this.entities[this.selectedEntity]?.formData?.[this.selected_menu] ?? [];
        },
    },
    async mounted() {
        await this.initEntities();
        await this.setCountryCodes();
    },
    created() {
        EventBus.$on("emailValidate", (data) => {
            this.isFormValid = data;
        });
        this.case_id = this.$store.getters.getCaseId;
        // if (!(this.getProduct && this.getProduct?.key)) this.$router.push({ name: "Case Products" })
        if (this.case_id == "NEW" && !(this.getProduct && this.getProduct?.key)) this.$router.push({name: "Case Products"});
    },

    methods: {
        async initEntities() {
            await this.getAllEntities();
            if (this.entities.length > 0) await this.changeSelectedEntity(0);
        },

        checkIfEntityIsDisabled(entity) {
            return this.getCaseMaxEntity[entity] === 0;
        },
        async getAllCountryCodes() {
            const url = "/whatsapp/country-code?conn=keep-alive";
            const result = await OsintAxios.get(url);
            return result.data;
        },
        async setCountryCodes() {
            this.allCountryCodes = await this.getAllCountryCodes();
            this.allCountryCodes.forEach((res) => {
                this.countryCodes.push({
                    res,
                    value: res.country_with_code,
                });
            });
        },

        addEntity(entity_type) {
            if (this.entities.filter((en) => en.entity_type === entity_type).length < this.getCaseMaxEntity[entity_type]) {
                let entity = {
                    entity_type: entity_type,
                    name: "",
                    entity_name: "",
                    error_section: [],
                };
                this.createEntity(entity);
            } else {
                this.$toast(`Can not create any more ${entity_type}`, {
                    position: "top-right",
                    hideProgressBar: false,
                });
            }
        },

        // addPerson() {
        //   let person = {
        //     // key: Math.round( Math.random() * 10),
        //     entity_type: 'person',
        //   }
        //   this.createEntity(person)
        // },
        async handleSubmitCase() {
            if (this.$refs["entity-panel"]) await this.$refs["entity-panel"]?.changeMenu({tab: `profile_data`}, true);
            let menus = this.entityInformation[0].menus.map((el) => el);
            let myEntities = [];

            for (const entity of this.entities) {
                const {isFetched, _id, index} = entity;
                if (!isFetched) await this.getSingleEntityInfo(_id, index);
                menus.forEach((menu, idx) => {
                    if (menu.type === entity.entity_type) {
                        let formData = this.getCaseEntityInfo[entity?.entity_type][menu.tab]?.["fields"]?.map((el) => ({
                            ...el,
                            field_name: el.placeholder,
                            placeholder: el.placeholder.split("_").join(" "),
                            label: el.label.split("_").join(" "),
                        }));
                        let values = entity?.formData?.[menu.tab] ?? [];
                        values = values.length ? values[0] : {};
                        formData = formData?.map((frm) => {
                            const placeholder = frm.placeholder.split(" ").join("_");
                            if (placeholder in values && values[placeholder] !== undefined && values[placeholder] !== "" && frm.mandatory) {
                                let obj = {...entity, is_error: false};
                                if (!myEntities.some((ent) => ent._id === obj._id)) myEntities.push(obj);
                                return {...frm, value: values[placeholder]};
                            } else {
                                // mandatory fiels are not filled
                                if (frm.mandatory) {
                                    let obj = {...entity, is_error: true};
                                    if (!obj.error_section?.includes(menu.tab)) obj.error_section?.push(menu.tab);
                                    if (!myEntities.some((ent) => ent._id === obj._id)) myEntities.push(obj);
                                    else myEntities = myEntities.map((entt) => (entt._id === obj._id ? obj : entt));
                                }
                                return frm;
                            }
                        });
                    }
                });
            }
            this.entities = cloneDeep(myEntities);
            let isErrorsInEntity = false;
            let isPlatformError = false;
            let profileDataError = false;
            let retentionPeriodError = false;
            if (this.entities.some((entt) => entt.is_error)) isErrorsInEntity = true;
            if (this.entities[0]?.formData?.social_platform_data?.[0]?.platform.some((el) => el.platform == "" || el.username == "")) isPlatformError = true;
            this.entities[0]?.formData?.profile_data?.forEach((el) => {
                if (el.first_name?.trim() === "" && el.last_name?.trim() === "") {
                    profileDataError = true;
                }
            });
            this.entities[0]?.formData?.profile_data?.forEach((el) => {
                if (el.data_retention_period === "" || (el.data_retention_period < 30 && el.data_retention_period > 3650)) {
                    retentionPeriodError = true;
                }
            });
            if (!isErrorsInEntity && !isPlatformError && this.isFormValid && !profileDataError && !retentionPeriodError) {
                this.isModalWarn = false;
                this.$modal.show("warning_modal");
            } else if (isErrorsInEntity || this.isFormValid == false || profileDataError) {
                this.$toast.error("Please enter valid and required information for all entities before submitting!");
            } else if (retentionPeriodError) this.$toast.error("please enter data retention period value greater than 30 and less than 3650");
            this.$forceUpdate();
            return;
            // if (this.currentEntity && this.currentEntity.formData && this.currentEntity.formData?.profile_data &&
            //   (this.currentEntity.formData?.profile_data.length > 0) &&
            //   (this.currentEntity.formData?.profile_data[0].first_name != ''
            //     || this.currentEntity.formData?.profile_data[0].company_name != '')) {
            //   this.isModalWarn = false;
            //   this.$modal.show('warning_modal')
            // } else {
            //   Object.assign(this.entities[this.selectedEntity], { is_error: true })
            //   this.$toast.error('Please enter required information for all entities before submitting!')
            //   this.$forceUpdate()
            // }
        },

        async createEntity(entity) {
            try {
                if (this.$refs["entity-panel"]) await this.$refs["entity-panel"]?.changeMenu({tab: `profile_data`}, true);
                let params = {
                    product_id: this.getProduct?.key,
                    entity_type: entity.entity_type,
                };
                const {data} = await axios.post(`/case-entity-data`, params);
                if (data && data.status) {
                    entity._id = data.entity_id;
                    // this.$toast(`New entity created!`, { position: 'top-right' })
                    this.entities.push(entity);
                    const index = this.entities.findIndex((ent) => ent._id == entity._id);
                    await this.getSingleEntityInfo(entity._id, index);
                    this.selectedEntity = index;
                } else {
                    this.$toast.error(data?.message ?? "Cannot create more " + entity.entity_type);
                }
            } catch (error) {
                console.error(error);
            }
        },

        async changeSelectedEntity($event) {
            if (this.$refs["entity-panel"]) await this.$refs["entity-panel"]?.changeMenu({tab: `profile_data`}, true);
            if (this.selectedEntity !== $event || this.selectedEntity == 0) {
                this.isCurrentEntityLoading = true;
                this.selectedEntity = $event;
                // this.selected_menu = ''
                // this.selected_menu = 'profile_data'
                let selectEntity = this.entities[this.selectedEntity];
                if (selectEntity._id) {
                    try {
                        if (!selectEntity.isFetched) await this.getSingleEntityInfo(selectEntity._id, this.selectedEntity);
                        this.isCurrentEntityLoading = false;
                        this.$forceUpdate();
                    } catch (error) {
                        console.log(error.message ?? "FAILED TO GET single ENTITY");
                    }
                }
            }
        },

        /***
         * id: entity id
         * index index in the array
         */
        async getSingleEntityInfo(id, index) {
            let {data} = await axios.get(`case-entity-data/${id}`);
            let selectEntity = this.entities[index];
            let entities = cloneDeep(this.entities);
            Object.assign(entities[index], {
                isFetched: true,
                formData: {
                    entity_type: selectEntity.entity_type,
                    product_id: this.getProduct?.key,
                },
            });
            if (data && data.data && data.data.profile_data && data.data.profile_data.length > 0) {
                data.data.profile_data[0].main_entity ? (data.data.profile_data[0].main_entity = ["Main Entity"]) : (data.data.profile_data[0].main_entity = []);
                if (this.dataRetention) data.data.profile_data.push({data_retention_period: this.dataRetention});
                if (this.legalReason) data.data.profile_data.push({legal_reason: this.legalReason});
            } else if (data && data.data && data.data.profile_data && data.data.profile_data.length == 0) {
                data.data.profile_data.push({data_retention_period: this.dataRetention || "", legal_reason: this.legalReason || ""});
            }
            Object.assign(entities[index].formData, data?.data);
            this.entities = entities;
        },
        setCaseStatus(status) {
            status = status.toLowerCase();
            if (status == "draft") {
                this.isFormReadOnly = false;
            }
            if (status === "done") {
                this.case_status = "Done";
            } else if (status.includes("monitoring")) {
                this.case_status = "Monitoring";
            } else {
                this.case_status = "In Progress";
            }
        },

        async getAllEntities() {
            this.isEntityLoading = true;
            const body = {
                product_id: this.getProduct?.key,
            };
            if (this.case_id !== "NEW") {
                this.isCaseInProgress = true;
                this.isFormReadOnly = true;
                body.case_id = this.case_id;
                delete body.product_id;
            }
            try {
                const {data} = await axios.post("case-entity-data/all", body);
                if (data?.data && data.data?.entities_data.length) {
                    this.entities = [];
                    this.entities = data?.data?.entities_data.map((el, idx) => ({...el, key: el._id, index: idx, error_section: new Array()}));
                    this.selected_menu = "profile_data";
                    if (data?.data?.case_status) {
                        this.setCaseStatus(data?.data?.case_status);
                    }
                    await this.$store.dispatch("fetchCaseEntityInfo", data?.data?.product_id);
                }
                this.isEntityLoading = false;
            } catch (error) {
                console.log(error.message ?? "Case entities load fail error!");
            }
        },

        async saveEntityInfo($event) {
            const selected_data = $event[this.selected_menu];
            let entities = cloneDeep(this.entities);
            if (!entities[this.selectedEntity].formData) {
                Object.assign(entities[this.selectedEntity], {
                    formData: {
                        entity_type: entities[this.selectedEntity].entity_type,
                        product_id: this.getProduct?.key,
                        // entity: this.entities[this.selectedEntity].name
                    },
                });
            }
            let error_section = entities[this.selectedEntity].error_section.filter((el) => el !== this.selected_menu);
            if (error_section.length === 0) Object.assign(entities[this.selectedEntity], {is_error: false});
            Object.assign(entities[this.selectedEntity], {error_section: error_section});
            Object.assign(entities[this.selectedEntity].formData, {[this.selected_menu]: selected_data});
            // this.entities = []
            this.entities = entities;
            if (this.selected_menu == "profile_data") {
                let name = "";
                entities[this.selectedEntity].entity_type == "person" ? (name = entities[this.selectedEntity]?.formData?.profile_data[0]?.first_name + " " + entities[this.selectedEntity]?.formData?.profile_data[0]?.last_name) : (name = entities[this.selectedEntity]?.formData?.profile_data[0]?.company_name);
                Object.assign(entities[this.selectedEntity], {entity_name: name});
                this.entities = entities;
                this.$forceUpdate();
            }
            await this.saveEntityData();
        },

        async saveEntityData() {
            const entityformData = this.currentEntity.formData;
            this.currentEntity.formData?.profile_data?.forEach((el) => {
                if (el.date_of_birth) el.date_of_birth = moment(el.date_of_birth).format("YYYY-MM-DD");
                if (el.inc_date) el.inc_date = moment(el.inc_date).format("YYYY-MM-DD");
                this.legalReason = el.legal_reason;
                if (el.data_retention_period) {
                    el.data_retention_unit = "days";
                    this.dataRetention = el.data_retention_period;
                }
            });
            this.entities.map((el) => {
                if (el.formData && el.formData.profile_data.length > 0 && (el.formData.profile_data[0].data_retention_period || el.formData.profile_data[0].legal_reason)) {
                    el.formData.profile_data[0].data_retention_period = this.dataRetention;
                    el.formData.profile_data[0].legal_reason = this.legalReason;
                } else if (el.formData && el.formData.profile_data.length == 0) {
                    el.formData.profile_data.push({data_retention_period: this.dataRetention || "", legal_reason: this.legalReason || ""});
                }
            });

            if (this.currentEntity.formData && this.currentEntity.formData[this.selected_menu] && this.selected_menu !== "profile_data") {
                let newArray = this.currentEntity.formData[this.selected_menu]?.filter((el) => this.trimObjValues(el));
                console.log(newArray);
                this.currentEntity.formData[this.selected_menu] = newArray;
            }
            let params = {
                product_id: this.getProduct?.key,
                entity_type: this.currentEntity.entity_type,
                // "entity": "",
            };
            params[this.selected_menu] = entityformData[this.selected_menu];
            if (this.currentEntity._id) {
                const {data} = await axios.put(`/case-entity-data/${this.currentEntity._id}`, params);
                if (data)
                    // this.$toast.success(`${this.currentEntity.entity_type === 'person' ? 'Person' : 'Company'} info  updated!`, { position: 'top-right' })
                    this.loaderController.showLoader = true;
                setTimeout(() => {
                    this.loaderController.showLoader = false;
                }, 800);
            }
        },

        trimObjValues(obj) {
            return Object.keys(obj).reduce((acc, curr) => {
                if (typeof obj[curr] === "object") {
                    obj[curr].map((el) => (el.username = el.username.trim()));
                    acc[curr] = obj[curr];
                } else acc[curr] = obj[curr].trim();
                return acc;
            }, {});
        },

        goToResearchView(caseid) {
            let url = `/ro/${caseid}`;
            window.open(url, "_blank");
        },

        async createCase() {
            if (this.getProduct) {
                try {
                    this.caseCreateLoader = true;
                    const {data} = await axios.post(`case-entity-data/create-case`, {
                        product_id: this.getProduct?.key,
                        product_name: this.getProduct?.name,
                    });
                    this.$modal.hide("warning_modal");
                    if (data && data.status) {
                        const {case_id} = data;
                        this.$toast.success(`Case created - ${case_id}`);
                        this.case_id = case_id;
                        this.$store.dispatch("setCaseId", case_id);
                        // CHANGED ACCORDING TO TECH-4543
                        // this.$router.push({ name: "Entities", params: { name: this.case_id } })
                        if (this.$route.meta.automated) window.open(`/automated/research/${case_id}/case/name`, "_self");
                        else {
                            window.open(`/case-list/`, "_self");
                            sessionStorage.clear();
                            localStorage.clear();
                        }

                        this.isCaseInProgress = true;
                        this.isFormReadOnly = true;
                        this.case_status = data.case_status || "In Progress";
                        this.caseCreateLoader = false;
                        await this.initEntities();
                    } else {
                        this.$toast.error(data && data.message);
                        if (data?.entity_data) this.entities = this.entities.map((ent) => ({...ent, is_error: data?.entity_data?.includes(ent._id)}));
                    }
                } catch (error) {
                    const newError = error.message ?? `ERROR CREATING CASE`;
                    this.$toast.error(newError, {position: "top-right"});
                }
            }
        },

        async handleCancelCase() {
            try {
                const {data} = await axios.post(`case-entity-data/delete-entities`, {
                    product_id: this.getProduct?.key,
                });
                this.$toast(data?.message, {position: "top-right"});
                this.$router.push({name: "Case Products"});
            } catch (error) {
                console.log(error?.message ?? "ERROR CANCELLING CASE");
            }
        },

        async deleteEntitiy($event) {
            const confirm = await this.$refs.confirm_modal.show({title: "Are you sure?", message: "Please confirm you want to delete this entity"});
            if (confirm) {
                this.$refs.confirm_modal.close();
                const url = `case-entity-data/${$event._id}`;
                try {
                    const {data} = await axios.delete(url);
                    if (data && data.status) {
                        const index = this.entities.findIndex((ent) => ent._id == $event._id);
                        this.entities.splice(index, 1);
                        this.selectedEntity = 0;
                    }
                } catch (error) {
                    console.log("ERROR DELETING ENTITIES :>> ", error);
                }
            }
        },

        getCaseStatusClasses() {
            let classes = "tw-pointer-none tw-user-select-none  ";
            if (this.case_status === "In Progress") classes += "tw-text-yellow-500 ";
            else if (this.case_status === "Done" || this.case_status) classes += "tw-text-green-500 ";
            // todo add background classes for other case statuses
            return classes;
        },
    },
};
