<template>
    <div class="tw-w-full tw-flex tw-box-border tw-flex-grow scroll tw-space-x-4" :style="{maxHeight: isReadOnly ? 'calc(100% - 134px)' : 'calc(100% - 192px)'}">
        <aside class="tw-border-0 tw-border-r tw-max-h-full tw-overflow-y-auto scroll-bar tw-w-60 tw-border-solid tw-border-gray-200 tw-top-0 tw-sticky tw-flex-shrink-0" style="max-width: 15rem">
            <nav class="tw-flex tw-flex-col" v-for="info in information" :key="'nav-id-temp' + info.id">
                <div class="tw-px-6">
                    <small class="tw-text-gray-500 tw-inline-block tw-text-xs tw-tracking-wider tw-capitalize tw-pt-5 tw-pb-3 tw-font-medium">
                        {{ info.label }}
                    </small>
                    <ul class="tw-flex tw--mx-6 tw-flex-col tw-my-1 tw-p-0">
                        <template v-for="menu in info.menus">
                            <li class="tw-flex tw-px-6 tw-cursor-pointer tw-items-center tw-tracking-wide tw-py-2.5 tw-border-0 tw-border-solid tw-text-sm" :key="menu.id" :class="getClasses(menu)" @click="changeMenu(menu, false)" v-if="menu.type == selectedEntity.entity_type">
                                <font-awesome-icon class="tw-mr-4" icon="list-alt"></font-awesome-icon>
                                <span class="tw-inline-block tw-capitalize tw-truncate">
                                    {{ menu.name }}
                                </span>
                                <!-- <span v-if="selectedEntity.error_section.includes(menu.tab)">
                                X 
                            </span> -->
                            </li>
                        </template>
                    </ul>
                </div>
            </nav>
        </aside>
        <section class="tw-p-6 tw-flex-grow tw-max-h-full tw-overflow-y-auto scroll-bar tw-box-border">
            <!-- <template v-if="current_menu === 'name'">
                <DynamicForm ref="name-form" @submit="setEntityName" headerText="Entity Name" submitText="Save" key="Add name" :configs="nameForm" class="xl:tw-w-1/2 lg:tw-w-2/3 md:tw-w-full tw-w-full tw-mx-auto tw-mb-5" />
            </template> -->
            <!--
            <template v-if="current_menu === 'addresses' " >
                Addresses
            </template>
            <template v-if="current_menu === 'education' " >
                education
            </template>
            <template v-if="current_menu === 'employment' " >
                employment
            </template>
            <template v-if="current_menu === 'social_profiles' " >
                social profiles
            </template> -->
            <template v-if="isCurrentEntityLoading">
                <div class="tw-flex tw-py-28 tw-flex-col tw-items-center tw-justify-center">
                    <neoLoader class="tw-w-10 tw-mb-4 tw-h-10" />
                    <span class="tw-text-gray-400 tw-text-sm"> Loading...Please wait! </span>
                </div>
            </template>
            <template v-else-if="current_menu === 'attachments_data'">
                <!-- <div class="folder">
                    <FileBrowser class="file--browser" :data="folders" :readOnly="isReadOnly" title="CLIENT UPLOADS" key="1" :preCase="true" :entityId="selectedEntity._id" />
                </div> -->
                <div class="tw-text-gray-800 tw-text-lg tw-text-center tw-font-medium tw-mb-2">CLIENT UPLOADS</div>
                <FileExplorer title="CLIENT UPLOADS" :readOnly="isReadOnly" :key_name="isCaseInProgress ? 'case_id' : 'product_id'" :key_value="isCaseInProgress ? selectedEntity.case_id : getProduct.key" />
            </template>
            <template v-else>
                <DynamicForm ref="entity-form" @submit="addEntitySection" :countryCodes="countryCodes" :readOnly="isReadOnly" :headerText="headerText" :values="formData" submitText="Save" :key="menu + 'entity' + selectedEntity._id" :configs="currentSelectedForm" class="xl:tw-w-1/2 lg:tw-w-2/3 md:tw-w-full tw-w-full tw-mx-auto tw-mb-5" />
            </template>
        </section>
    </div>
</template>

<script>
import {uniqBy, isEqual} from "lodash";
// import { FILE_MANAGER_FOLDERS } from "@/utils/constants";
import neoLoader from "@/components/n-loader";
import {mapGetters} from "vuex";
import {EventBus} from "@/main.js";
import moment from "moment";

// Dynamic imports for components that are not used on first load
const DynamicForm = () => import("@/components/DynamicForm");
// const FileBrowser = () => import("@/components/filebrowser");
const FileExplorer = () => import("@/containers/file-explorer");

export default {
    components: {
        DynamicForm,
        neoLoader,
        // FileBrowser,
        FileExplorer,
    },
    props: {
        information: {
            type: Array,
            required: true,
            default: () => [],
        },
        menu: {
            type: String,
            required: true,
            default: "name",
        },
        entityInfo: {
            type: Object,
            required: true,
            default: null,
        },
        formData: {
            type: Array,
            default: () => [],
        },
        entityName: {
            type: Array,
            default: () => [],
        },
        selectedEntity: {
            type: Object,
        },
        isCurrentEntityLoading: {
            type: Boolean,
            default: false,
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        isCaseInProgress: {
            type: Boolean,
            default: false,
        },
        countryCodes: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            entityFormData: {
                profile_data: [],
                other_name_data: [],
                addresses_data: [],
                education_data: [],
                employment_data: [],
                email_data: [],
                phone_data: [],
                relation_data: [],
                related_outputs: [],
                social_profile_data: [],
                other_data: [],
            },
            // folders: FILE_MANAGER_FOLDERS,
        };
    },
    watch: {},
    computed: {
        ...mapGetters(["getUserAuthDetails", "getReadOnlyMode", "getProduct"]),

        isNeotasUser() {
            return this.getUserAuthDetails.email.includes("@neotas.com");
        },

        current_menu: {
            get: function() {
                return this.menu;
            },
            set: function(tabName) {
                this.$emit("changeMenu", tabName);
            },
        },
        currentSelectedForm() {
            return this.entityInfo?.[this.selectedEntity?.entity_type]?.[this.current_menu]?.["fields"]?.map((el) => ({
                ...el,
                field_name: el.placeholder,
                placeholder: el.placeholder.split("_").join(" "),
                label: el.label.split("_").join(" "),
            }));
        },
        headerText() {
            return this.entityInfo?.[this.selectedEntity?.entity_type]?.[this.current_menu]?.["heading"] ?? "";
        },
        getSelectedEntityType() {
            return this.entityInfo?.[this.selectedEntity?.entity_type]?.[this.current_menu]?.["entity_type"] ?? "";
        },
        getFormValues() {
            if (this.$refs["entity-form"]?.validate() == false) {
                this.$refs["entity-form"]?.$children[0]?.$options?.propsData?.value?.forEach((el) => {
                    if (this.$refs["entity-form"]?.validate() == false && el.attrs?.label == "Email Address" && el.attrs?.value && el.attrs?.value != "") {
                        EventBus.$emit("emailValidate", this.$refs["entity-form"]?.validate());
                    }
                });
            } else if (this.$refs["entity-form"]?.validate()) EventBus.$emit("emailValidate", this.$refs["entity-form"]?.validate());
            return this.formData;
        },
    },
    methods: {
        async changeMenu({tab}, tabChanged = false) {
            let counter = 0;
            let emailFlag = 0;
            let companyFlag = 0;
            let dataRetentionValue = 0;
            if (this.currentSelectedForm.length === 0) {
                this.current_menu = tab;
                return;
            }
            if (this.current_menu === tab && !tabChanged) return;
            let isChanged = false;
            let models = this.$refs["entity-form"].models;
            let values = this.getFormValues.length > 0 ? this.getFormValues[0] : [];
            if (this.$refs["entity-form"]?.validate() == false) {
                this.$refs["entity-form"].models.forEach((el) => {
                    if (el?.attrs?.field_name == "first_name" && (!el?.attrs?.value || el.attrs.value.trim() === "")) {
                        counter++;
                    } else if (el?.attrs?.field_name == "last_name" && (!el?.attrs?.value || el.attrs.value.trim() === "")) {
                        counter++;
                    } else if (el?.attrs?.field_name == "data_retention_period" && !el?.attrs?.value) {
                        counter++;
                    } else if (el?.attrs?.field_name == "data_retention_period" && el?.attrs?.value && (el.attrs.value < 30 || el.attrs.value >= 3650)) {
                        dataRetentionValue++;
                    }
                });

                this.$refs["entity-form"]?.$children[0]?.$options.propsData.value?.forEach((el) => {
                    if (el.attrs?.label == "Email Address" && el.attrs?.value) {
                        const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
                        if (!emailRegex.test(el.attrs.value)) emailFlag++;
                    }
                });
                if (counter == 0 && emailFlag == 0 && dataRetentionValue == 0) {
                    this.$refs["entity-form"]?.onSubmit();
                    this.current_menu = tab;
                } else if (counter > 0 || emailFlag > 0 || dataRetentionValue > 0) {
                    if (counter > 0) tabChanged ? "" : this.$toast.error("Please enter all the required fields before changing the tabs!", {maxToasts: 2});
                    else if (emailFlag > 0) tabChanged ? "" : this.$toast.error("Please enter valid email address before changing the tabs!");
                    else if (dataRetentionValue > 0) {
                        tabChanged ? this.$refs["entity-form"]?.onSubmit() : this.$toast.error("please enter data retention value greater than 30 and less than 3650");
                    }
                }
            } else if (!this.$refs["entity-form"].validate() && this.getFormValues?.length <= 0 && this.isCaseInProgress == false) {
                tabChanged ? "" : this.$toast.error("Please enter all the required fields before changing the tabs!", {maxToasts: 2});
            } else if (this.$refs["entity-form"].validate()) {
                this.$refs["entity-form"].models.forEach((el) => {
                    if (el?.attrs?.field_name == "first_name" && (!el?.attrs?.value || el.attrs.value.trim() === "")) {
                        counter++;
                    } else if (el?.attrs?.field_name == "last_name" && (!el?.attrs?.value || el.attrs.value.trim() === "")) {
                        counter++;
                    } else if (el?.attrs?.field_name == "data_retention_period" && !el?.attrs?.value) {
                        counter++;
                    } else if (el?.attrs?.field_name == "data_retention_period" && el?.attrs?.value && (el.attrs.value < 30 || el.attrs.value >= 3650)) {
                        dataRetentionValue++;
                    }
                    if (el.type == "combo") {
                        el?.attrs?.value?.map((el1) => {
                            if ((el1.platform != "" && el1.username == "") || (el1.platform == "" && el1.username != "")) {
                                counter++;
                            }
                        });
                    }
                    if (el.length)
                        el?.forEach((el1) => {
                            if (el1.type == "block" && el1.attrs?.label == "Phone") {
                                if ((el1?.attrs?.value[0].value != undefined && el1?.attrs?.value[0].value != "" && (el1?.attrs?.value[1].value == undefined || el1?.attrs?.value[1].value == "")) || ((el1?.attrs?.value[0].value == undefined || el1?.attrs?.value[0].value == "") && el1?.attrs?.value[1].value != undefined && el1?.attrs?.value[1].value != "")) {
                                    counter++;
                                }
                            }
                        });
                    if (el.length)
                        el?.forEach((el1) => {
                            if (el1.type == "block" && el1.attrs?.label == "Company") {
                                var exists = Object.keys(el1.attrs?.value).some((k) => {
                                    if (el1.attrs?.value[k].value) return true;
                                });
                                if (exists && !el1.attrs?.value[1].value) {
                                    companyFlag++;
                                }
                            }
                        });
                });

                if (counter > 0) {
                    tabChanged ? this.$refs["entity-form"]?.onSubmit() : this.$toast.error("Please enter all the required fields before changing the tabs!");
                } else if (companyFlag > 0) {
                    tabChanged ? this.$refs["entity-form"]?.onSubmit() : this.$toast.error("Please enter employer name befor changing the tabs");
                } else if (dataRetentionValue > 0) {
                    tabChanged ? this.$refs["entity-form"]?.onSubmit() : this.$toast.error("Please enter data retention value greater than 30 and less than 3650");
                } else {
                    this.$refs["entity-form"]?.onSubmit();
                    this.current_menu = tab;
                }
            } else {
                if (models)
                    models.forEach((mod) => {
                        let isMultiple = false;
                        isMultiple = Array.isArray(mod);
                        if (isMultiple) {
                            let length = mod.filter((el) => el.attrs.value && el.attrs.value !== "").length;
                            if (length !== 0 && length > this.getFormValues.length) {
                                isChanged = true;
                            } else {
                                mod.forEach((md) => {
                                    if (this.getFormValues.find((val) => isEqual(val[md.attrs.field_name], md.attrs.value)) === undefined) {
                                        isChanged = true;
                                    }
                                });
                            }
                        } else {
                            if ((mod.attrs.value !== undefined || mod.attrs.value !== "") && mod.attrs.field_name in values && !isEqual(values[mod.attrs.field_name], mod.attrs.value)) {
                                isChanged = true;
                            }
                        }
                    });

                if (isChanged) {
                    this.$refs["entity-form"]?.onSubmit();
                    this.current_menu = tab;
                } else {
                    tabChanged ? "" : this.$toast.error("Please enter all the required fields before changing the tabs!");
                }
            }
        },

        createEntityData(ele) {
            ele = uniqBy(ele, "value");
            ele.forEach((mod) => {
                if (mod.value === "" || mod.value === undefined) return;
                let multi_params;
                if (Array.isArray(mod.value)) {
                    mod.value.forEach((val) => {
                        if (val.value === "" || val.value === undefined) return;
                        if (!multi_params)
                            multi_params = {
                                entity_type: this.getSelectedEntityType,
                            };
                        if (val.type === "date") multi_params[val.placeholder.split(" ").join("_")] = val.value ? moment(val.value).format("YYYY-MM-DD") : "";
                        else if (val.type === "dropdown" && val.placeholder === "country_code") multi_params[val.placeholder.split(" ").join("_")] = val.value?.res?.country_phone_code ? "+" + val.value?.res?.country_phone_code : val.value;
                        else multi_params[val.placeholder.split(" ").join("_")] = val.value ?? "";
                    });
                    if (multi_params) this.entityFormData[this.current_menu].push(multi_params);
                } else {
                    multi_params = {
                        entity_type: this.getSelectedEntityType,
                    };

                    if (mod.type === "date") multi_params[mod.placeholder.split(" ").join("_")] = mod.value ? moment(mod.value).format("YYYY-MM-DD") : "";
                    else multi_params[mod.placeholder.split(" ").join("_")] = mod.value ?? "";
                    this.entityFormData[this.current_menu].push(multi_params);
                }
            });
        },

        /**
         * Add selected format data section to Entity
         */
        addEntitySection(models) {
            if (this.$refs["entity-form"].validate()) {
                let param_data = {
                    entity_type: this.getSelectedEntityType,
                };
                let counter = 0;
                let isMultiple = false;
                models.forEach((ele) => {
                    isMultiple = Array.isArray(ele);
                    if (isMultiple) {
                        // models = models.flat();

                        for (const val in ele) {
                            if (ele[val].placeholder == "phone number") {
                                if (ele[val].value[0].value && !ele[val].value[1].value) {
                                    this.$toast.error("please enter phone number");
                                    counter++;
                                } else if (!ele[val].value[0].value && ele[val].value[1].value) {
                                    this.$toast.error("Please select country code");
                                    counter++;
                                }
                            } else if (ele[val].placeholder == "company") {
                                var exists = Object.keys(ele[val].value).some(function(k) {
                                    if (ele[val].value[k].value) return true;
                                });
                                if (exists && !ele[val].value[1].value) {
                                    this.$toast.error("please enter employer name");
                                    counter++;
                                }
                            }
                        }
                        if (counter == 0) {
                            Object.assign(this.entityFormData, {[this.current_menu]: []});
                            this.createEntityData(ele);
                        }
                    } else {
                        if (ele.placeholder == "main entity") {
                            if (ele.type === "date") param_data[ele.placeholder.split(" ").join("_")] = ele.value ? moment(ele.value).format("YYYY-MM-DD") : "";
                            else param_data[ele.placeholder.split(" ").join("_")] = ele.value ?? [];
                        } else {
                            if (Array.isArray(ele.value)) {
                                for (const val in ele.value) {
                                    if (ele.value[val].type === "date") param_data[ele.value[val].placeholder?.split(" ").join("_")] = ele.value[val].value ? moment(ele.value[val].value).format("YYYY-MM-DD") : "";
                                    else param_data[ele.value[val].placeholder?.split(" ").join("_")] = ele.value[val].value ?? "";
                                }
                            } else {
                                if (ele.type === "date") param_data[ele.placeholder.split(" ").join("_")] = ele.value ? moment(ele.value).format("YYYY-MM-DD") : "";
                                else param_data[ele.placeholder.split(" ").join("_")] = ele.value ?? "";
                            }
                        }
                        if (ele.label == "Platform") {
                            let counter = 0;
                            ele.value?.map((el1) => {
                                if ((el1.platform != "" && el1.username == "") || (el1.platform == "" && el1.username != "")) counter++;
                            });
                            if (counter > 0) this.$toast.error("Please enter all required fields before saving!");
                            else param_data[ele.placeholder] = ele.value?.filter((el) => el.platform || el.username) ?? [];
                        }
                    }
                });
                if (!isMultiple) {
                    Object.assign(this.entityFormData, {[this.current_menu]: [param_data]});
                    // this.entityFormData[this.current_menu] = [param_data];
                }
                if (counter == 0) this.$emit("saveEntityInfo", this.entityFormData);
            } else if (this.$refs["entity-form"]?.validate() == false) {
                let isMultiple = false;
                let counter = 0;
                models.forEach((ele) => {
                    isMultiple = Array.isArray(ele);
                    if (isMultiple) {
                        // models = models.flat();
                        for (const val in ele) {
                            if (ele[val].placeholder == "email") {
                                if (ele[val].value !== undefined && ele[val].value !== "") {
                                    const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
                                    if (!emailRegex.test(ele[val].value)) counter++;
                                }
                            }
                        }
                        if (counter == 0) {
                            Object.assign(this.entityFormData, {[this.current_menu]: []});
                            this.createEntityData(ele);
                        } else this.$toast.error("Please enter valid email address!");
                    }
                });
                if (counter == 0) this.$emit("saveEntityInfo", this.entityFormData);
                // let emailcounter = 0;
                // this.$refs["entity-form"]?.$children[0]?.$options?.propsData?.value?.forEach(el => {
                //     if(el.attrs?.label == "Email Address" && el.attrs?.value && el.attrs?.value!="" && typeof(el.attrs.value) != "undefined") {
                //         const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
                //         if(!emailRegex.test(el.attrs.value))
                //             emailcounter++;
                //         else { if(emailcounter == 0) {
                //             Object.assign(this.entityFormData, { [this.current_menu]: [] });
                //             this.createEntityData(ele);
                //         }}
                //     }})
                // if(emailcounter>0) this.$toast.error("Please enter valid email address!");
            } else if (this.isCaseInProgress == false) {
                this.$toast.error("Please enter all required fields before saving!");
            }
        },

        /**
         * Get html classes for styling
         */
        getClasses(menu) {
            let classes = "";
            // selected menu
            if (this.current_menu === menu.tab) {
                if (this.selectedEntity?.error_section && this.selectedEntity?.error_section?.includes(menu.tab)) {
                    classes = "  tw-bg-red-50 tw-shadow-sm tw-border-r-6 tw-border-red-500  ";
                } else classes += " tw-bg-blue-50 tw-shadow-sm tw-border-r-6 tw-border-brand ";
            } else {
                if (this.selectedEntity?.error_section && this.selectedEntity?.error_section?.includes(menu.tab)) {
                    classes += " tw-bg-gray-50  hover:tw-bg-red-50 tw-text-red-500 ";
                } else classes += " tw-bg-white hover:tw-bg-gray-50 hover:tw-text-blue-700 tw-text-gray-600";
            }
            return classes;
            // current_menu === menu.tab ? 'tw-bg-blue-50 tw-shadow-sm tw-border-r-6 tw-border-brand' : 'hover:tw-bg-gray-50 hover:tw-text-blue-700 tw-text-gray-600'
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
.file--browser {
    // border:solid 1px #e6e6e6;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
}
</style>
