<template>
    <div v-if="entities.length" class="tw-border-b tw-border-0 tw-flex-shrink-0 tw-h-16 tw-border-gray-200 tw-px-6 tw-overflow-x-auto scroll-bar tw-border-solid tw-flex">
        <div class="tw-max-w-xs tw-w-44 tw-py-2.5 tw-px-3.5 tw-rounded-t-lg tw-relative tw-mt-2 tw-cursor-pointer tw-transitizon-colors tw-flex-shrink-0 tw-group tw-select-none" :class="getTabClasses(entity, idx)" @click="selectEntity(idx)" v-for="(entity, idx) in entities" :key="entity._id">
            <div class="tw-flex tw-justify-between tw-items-center">
                <span class="icon tw-text-sm tw-mr-1">
                    <template v-if="entity.entity_type === 'person'">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        </svg>
                    </template>
                    <template v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-briefcase-fill" viewBox="0 0 16 16">
                            <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z" />
                            <path d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z" />
                        </svg>
                    </template>
                </span>
                <span class="tw-text-xs tw-tracking-wide tw-font-medium tw-capitalize tw-mr-auto tw-pb-1 tw-opacity-75">{{ entity.entity_type }}</span>
                <!-- <span class="editbutton tw-opacity-75 hover:tw-opacity-100 group-hover:tw-opacity-100" v-if="!isReadOnly">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                </span> -->
                <span class="tw-flex-shrink-0 tw-opacity-50 hover:tw-opacity-100 tw-ml-2" @click.stop="$emit('deleteEntitiy', entity)" v-if="!isReadOnly">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                    </svg>
                </span>
            </div>
            <span class="tw-capitalize tw-flex tw-justify-between tw-truncate">
                <!-- <span class="tw-truncate "> -->
                {{ getEntityName(entity) }}
                <!-- </span> -->
            </span>
            <!-- divider  -->
            <span class="tw-absolute tw-h-10 tw-top-3 tw-bg-gray-300 tw-right-0" style="width: 1px" v-if="!isSelectedEntity(idx)"></span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        entities: {
            type: Array,
            default: () => [],
        },
        selectedEntity: {
            type: Number,
            default: 0,
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        selectedEntityIndex: {
            get: function() {
                return this.selectedEntity;
            },
            set: function(newVal) {
                this.$emit("changeSelectedEntity", newVal);
            },
        },
        currentEntity() {
            return this.entities.length > 0 ? this.entities[this.selectedEntity] : {};
        },
        // selectedEntityItem() {
        //     return this.entities.length ? this.entities[this.selectedEntityIndex] : {};
        // },
    },
    methods: {
        selectEntity(index) {
            this.selectedEntityIndex = index;
        },
        isSelectedEntity(index) {
            return this.selectedEntityIndex === index;
        },

        getEntityName(entity) {
            let name = " ";
            if (entity.entity_name && entity.entity_name !== "") name = entity.entity_name;
            // else {
            //     if (this.currentEntity.entity_type == "company") name = this.currentEntity?.formData?.profile_data[0]?.company_name || ''
            //     else name = (this.currentEntity?.formData?.profile_data[0]?.first_name || ''  + ' ' + this.currentEntity?.formData?.profile_data[0]?.last_name || '') || ''
            // }
            return name;
        },
        getTabClasses(entity, idx) {
            let classes = "";
            if (this.isSelectedEntity(idx)) {
                entity.is_error ? (classes += " tw-border-0 tw-text-white  tw-border-b-1 tw-bg-red-500 tw-border-red-400 tw-border-solid") : (classes += " tw-bg-gray-700 hover:tw-bg-gray-800 tw-text-white");
            } else {
                entity.is_error ? (classes += " tw-border-0 tw-border-b-4 tw-bg-red-50  tw-border-red-400 tw-border-solid tw-bg-white hover:tw-bg-blue-50 hover:tw-text-gray-800 tw-text-gray-700") : (classes += " tw-bg-white hover:tw-bg-blue-50 hover:tw-text-gray-800 tw-text-gray-700");
            }
            return classes;
        },
    },
};
</script>

<style></style>
